import { Footer } from 'antd/es/layout/layout';

import { Button, Carousel, Divider, Popover } from 'antd';
import { BilibiliOutlined, TikTokOutlined, WechatOutlined, XOutlined } from '@ant-design/icons';
import { useGlobalStore } from '@/store';

import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { XiaohongshuIcon } from '@/components/Icon/XIaohongshu';
import GroupQrCode from '@/components/Feedback/GroupQRCode';
import { isChineseDomain } from "@/utils";
const isChineseDomainFlag = isChineseDomain();

function Home() {
  const user = useGlobalStore((state) => state.currentUser);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const [ t ] = useTranslation();
  
  const handleShowLoginModal = () => {
    location.href='/workflow#collectedWorkflow'
  }

  return (
    <div className={styles.home}>
      <div className={styles.content}>
        <div className={styles.sloganSection}>
          <div className={styles.sloganContent}>
            <div className={styles.slogan}>
              {/* {t('home.sloganTitle', {
                replace: {
                  appName: <strong style={{ color: '#ff00cc', }}> Malette.Art</strong>
                }
              })} */}
              <Trans i18nKey="home.sloganTitle" components={{ 
                  bold: <strong style={{ color: '#ff00cc', }}></strong> ,
                  br: <br/>
                }} 
                values={{ appName: t('home.appName') }}
              />
            </div>
            <div className="mbtn">
              <Button 
                onClick={handleShowLoginModal} 
                size="large" 
                type="primary" 
                className={styles.getStartedBtn}
              >
                {t('home.getStarted')}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.designSection}>
          <div className={styles.descSection}>
            <div className={styles.designTitle}>
              <a href='/explore' target='_blank'>{t('home.AIWorkflow')}</a>
            </div>
            <div className={styles.designDesc}>
              {t('home.AIWorkflowDesc')}
            </div>
          </div>
          <div className={styles.designContent}>
            <div className={styles.carouselWrapper}>
              <Carousel effect="fade" autoplay >
                <div className={styles.imageWrapper}>
                  <img src='https://ablula.oss-accelerate.aliyuncs.com/malette/assets/design-show-03.jpg' />
                </div>
                <div className={styles.imageWrapper}>
                  <img src='https://ablula.oss-accelerate.aliyuncs.com/malette/assets/design-show-05.jpg' />
                </div>
                <div className={styles.imageWrapper}>
                  <img src='https://ablula.oss-accelerate.aliyuncs.com/malette/assets/design-show-04.jpg' />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        <Divider />
        <div className={styles.designSection}>
          <div className={styles.descSection}>
            <div className={styles.designTitle}>
            <a href='/comfyui' target='_blank'>{t('home.CloudComfyUI')}</a>
            </div>
            <div className={styles.designDesc}>
              {t('home.CloudComfyUIDesc')}
            </div>
          </div>
          <div className={styles.designContent}>
            <div className={styles.carouselWrapper}>
              <Carousel effect="fade" autoplay >
                <div className={styles.imageWrapper}>
                  <img src='https://ablula.oss-accelerate.aliyuncs.com/malette/assets/design-show-06.webp' />
                </div>
                <div className={styles.imageWrapper}>
                  <img src='https://ablula.oss-accelerate.aliyuncs.com/malette/assets/design-show-07.webp' />
                </div>
                <div className={styles.imageWrapper}>
                  <img src='https://ablula.oss-accelerate.aliyuncs.com/malette/assets/design-show-08.webp' />
                </div>
                <div className={styles.imageWrapper}>
                  <img src='https://ablula.oss-accelerate.aliyuncs.com/malette/assets/design-show-09.webp' />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.infoContent}>
          <div className={styles.logoWrapper}>
            <div className={styles.logo}>
            </div>
            <span>{t('home.malette')}</span>
          </div>
          <div className={styles.infoSection}>
            <div className={styles.infoSectionTitle}>
              {t('home.product')}
            </div>
            <div className={styles.infoSectionList}>
              <div 
                className={styles.infoSectionItem}
                onClick={() => window.open('/workflow/d7c3573b77de45b')}
              >
                <div className={styles.infoSectionItemTitle}>
                  {t('home.muscleKing')}
                </div>
              </div>
              <div 
                className={styles.infoSectionItem}
                onClick={() => window.open('/workflow/text-sticker')}
              >
                <div className={styles.infoSectionItemTitle}>
                  {t('home.textSticker')}
                </div>
              </div>
              <div 
                className={styles.infoSectionItem}
                onClick={() => window.open('/workflow/sticker-maker')}
              >
                <div className={styles.infoSectionItemTitle}>
                  {t('home.stickerMaker')}
                </div>
              </div>
              <div 
                className={styles.infoSectionItem}
                onClick={() => window.open('/workflow/5891d8d5d83848c')}
              >
                <div className={styles.infoSectionItemTitle}>
                  {t('home.winkPortraits')}
                </div>
              </div>
              <div 
                className={styles.infoSectionItem}
                onClick={() => window.open('/workflow/ea8c659d77d5401')}
              >
                <div className={styles.infoSectionItemTitle}>
                  {t('home.socialCard')}
                </div>
              </div>
              <div 
                className={styles.infoSectionItem}
                onClick={() => window.open('/comfyui')}
              >
                <div className={styles.infoSectionItemTitle}>
                  {t('home.CloudComfyUI')}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.infoSection}>
            <div className={styles.infoSectionTitle}>
              {t('home.getStarted')}
            </div>
            <div className={styles.infoSectionList}>
              <div className={styles.infoSectionItem}>
                <div className={styles.infoSectionItemTitle}>
                  {t('home.pricing')} <span style={{ fontSize: '0.8rem' }}>({t('home.comingSoon')})</span>
                </div>
              </div>
              <div className={styles.infoSectionItem}>
                <div className={styles.infoSectionItemTitle}>
                  {t('home.Tutorials')} <span style={{ fontSize: '0.8rem' }}>({t('home.comingSoon')})</span>
                </div>
              </div>
              <div className={styles.infoSectionItem}>
                <div className={styles.infoSectionItemTitle}>
                  {t('home.FAQ')} <span style={{ fontSize: '0.8rem' }}>({t('home.comingSoon')})</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.infoSection}>
            <div className={styles.infoSectionTitle}>
              {t('home.connect')}
            </div>
            <div className={styles.infoSectionList}>
              <div className={styles.infoSectionItem}>
                <div className={styles.infoSectionItemTitle}>
                  <Button 
                    style={{ color: '#999999' }} 
                    type="text"
                    onClick={() => window.open('https://x.com/jinchanzx')}
                  >
                    <XOutlined style={{ fontSize: '1.5rem' }} />
                  </Button>
                </div>
              </div>
              <div className={styles.infoSectionItem}>
                <div className={styles.infoSectionItemTitle}>
                  <Popover title={null} trigger="hover" placement="left" content={<GroupQrCode />}>
                    <Button 
                      style={{ color: '#999999' }} 
                      type="text"
                    >
                      <WechatOutlined style={{ fontSize: '1.5rem' }} />
                    </Button>
                  </Popover>
                </div>
              </div>
              <div className={styles.infoSectionItem}>
                <div className={styles.infoSectionItemTitle}>
                  <Button 
                    style={{ color: '#999999' }} 
                    type="text"
                    onClick={() => window.open('https://space.bilibili.com/6962628')}
                  >
                    <BilibiliOutlined style={{ fontSize: '1.5rem' }} />
                  </Button>
                </div>
              </div>
              <div className={styles.infoSectionItem}>
                <div className={styles.infoSectionItemTitle}>
                  <Popover title={null} trigger="hover" placement="left" content={
                    <GroupQrCode qrSrc='https://ablula.oss-accelerate.aliyuncs.com/malette/assets/douyin-qr.JPG!xs' />
                  }>
                    <Button 
                      style={{ color: '#999999' }} 
                      type="text"
                      onClick={() => window.open('https://www.douyin.com/user/MS4wLjABAAAAqtjusa0XS6bZK4TpffINyBm3aMRF1HGEexdMqQmSAz8uVqtfncXQHJ2FZ0tGPY0u')}
                    >
                      <TikTokOutlined style={{ fontSize: '1.5rem' }} />
                    </Button>
                  </Popover>
                </div>
              </div>
              <div className={styles.infoSectionItem}>
                <div className={styles.infoSectionItemTitle}>
                  <Button 
                    style={{ color: '#999999' }} 
                    type="text"
                    onClick={() => window.open('https://www.xiaohongshu.com/user/profile/6155a9b80000000002025292?xhsshare=CopyLink&appuid=6155a9b80000000002025292&apptime=1709412181')}
                  >
                    <XiaohongshuIcon style={{ fontSize: '1.5rem' }} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        isChineseDomainFlag ? 
        <Footer className={styles.footer} style={{ textAlign: 'center' }}>
          Malette.Art ©{(new Date()).getFullYear()} 版权所有 
          |&nbsp; <a target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn">蜀ICP备2023029381号</a> 
          &nbsp;|&nbsp; ICP许可证 川B2-20240572
        </Footer> : null
      }
    </div>
  );
}

export default Home;
