import { WorkflowItem, DEFAULT_COVER } from "@/constants";

import styles from './index.module.scss';
import { Button, Empty, Upload } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "@/store";

const WorkflowItemComp: React.FC<IWorkflowItemProps> = (props: IWorkflowItemProps) => {
  const { item, onEdit, onlyPreview } = props;
  const { name, description, cover, ownerId, publicId } = item;
  const fileExt = (cover || DEFAULT_COVER)?.split('!')[0]?.split('?')[0].split('.').pop() || 'png';
  const [hover, setHover] = useState(false);
  const [t] = useTranslation();

  const handleEdit = () => {
    onEdit?.(item);
  };

  const handleRunWorkflow = () => {
    window.open(`/workflow/${item.code}`);
  }

  return <div key={publicId} className={styles.workItem}>
    <div
      onMouseEnter={() => setHover(true)} // 设置当前hover的项目索引
      onMouseLeave={() => setHover(false)} // 清除hover索引
      className={styles.coverWrapper}>
      {
        ['webm', 'mp4'].includes(fileExt) ? <video src={cover} controls loop /> 
        : <img src={cover || DEFAULT_COVER} alt={name} /> 
      }
      <div className={styles.coverMask}>
        {
          hover ? <div className={styles.actions}>
            { onlyPreview ? null : <Button type="primary" onClick={handleEdit}>
              {t('editApp')}
            </Button> }
            <Button type="primary" onClick={handleRunWorkflow}>
              {t('openApp')}
            </Button>
          </div> : null
        }
      </div>
    </div>
    <div className={styles.workItemBottom}>
      <div className={styles.ownerAvatar}>
        <img src={`/profile/avatar/${ownerId}`} />
      </div>
      <div className={styles.workItemDesc}>
        <div className={styles.workItemTitle}>{name}</div>
        <div className={styles.workItemDescription}>{description || '暂无描述'}</div>
      </div>
    </div>
  </div>;
};

const WorkflowList: React.FC<{ 
  onlyPreview?: boolean,
  list: WorkflowItem[], 
  onEdit?: (item: WorkflowItem) => void ,
  handleWorkflowUpload?: (file: File) => Promise<boolean>,
}> = (props) => {
  const { list, onEdit, onlyPreview, handleWorkflowUpload } = props;
  const [t] = useTranslation();
  const currentUser = useGlobalStore((state) => state.currentUser);

  return list.length ? <div className={styles.workflowList}>
     {list.map((workflow, index) => {
        return <WorkflowItemComp 
          index={index} 
          item={workflow}
          onEdit={onEdit}
          onlyPreview={onlyPreview}/>
      })}
    </div> : <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{ height: 60 }}
      description={
        <span>
          {t('NoWorkflow')}
        </span>
      }
    >
    <Upload 
      disabled={!currentUser?.publicId}
      accept=".json,.jpeg,.png,.webp"
      itemRender={() => null}
      beforeUpload={handleWorkflowUpload} 
    >
      <Button disabled={!currentUser?.publicId} type="dashed">立即上传</Button>
    </Upload>
  </Empty>;
};


export default WorkflowList;