
import { Button, Spin, notification } from 'antd';
import style from './index.module.scss';
import { useEffect, useState } from 'react';
import { WorkflowItem } from "@/constants";
import { listWorkflows } from '@/services/Workflow';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGlobalStore } from '@/store';
import WorkflowList from '../workflow/list';

export interface IWorkflowItemProps {
  item: WorkflowItem;
  onEdit?: (item?: WorkflowItem) => void;
  onlyPreview?: boolean;
}

const WorkflowHome: React.FC = () => {
  const [ t ] = useTranslation();
  const currentUser = useGlobalStore((state) => state.currentUser);
  const [ defaultWorkflowList, setDefaultWorkflowList ] = useState<WorkflowItem[]>([]);

  useEffect(() => {

    const fetchDefaultWorkflowList = async () => {
      try {
        const response = await listWorkflows({ isDefault: true });
        const workflowList = response.data?.map(item => {
          return {
            ...item,
            contentTpl: JSON.parse(item?.contentTpl || '{}'),
            paramTpl: JSON.parse(item?.paramTpl || '{}'),
            outputTpl: JSON.parse(item?.outputTpl || '{}'),
          };
        });
        setDefaultWorkflowList(workflowList);
      } catch (error) {
        notification.error({ message: '获取默认工作流列表失败', description: error.message });
      }
    };

    fetchDefaultWorkflowList();
  }, []);

  const handleMakeAIApp = () => {
    window.location.href = '/workflow/app/new';
  };

  return <div className={style.WorkflowManagement}>
    <div className={style.action}>
      <Button 
        type="primary"
        onClick={() => handleMakeAIApp()}
        disabled={!currentUser?.publicId} 
      >
          {t('UploadWorkflow')}
      </Button>
    </div>
    <WorkflowList onlyPreview list={defaultWorkflowList} />
  </div>
};

export default WorkflowHome;