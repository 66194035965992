import AvatarUploader from "@/components/AvatarUploader"
import { Input, Form, Switch, Button, message, FormInstance } from "antd"

import style from "./index.module.scss"
import { DeleteOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { FieldData, WorkflowItem } from "@/constants";
import { uuid } from "@/utils";

const WorkflowPrivateSwitch = (props) => {

  const { value, onChange, ...restProps } = props;

  return <div>
    <Switch {...restProps} checked={value === 'N'} onChange={(value) => {
      onChange?.(value ? 'N' : 'Y');
    }} />
  </div>
}

const InviteCodeInputs = (props) => {
  const { value, onChange } = props;
  const [ inviteCodes, setInviteCodes ] = useState<string[]>(value || ['']);
  useEffect(() => {
    setInviteCodes(value || ['']);
  }, [value]);

  const appendParam = () => {
    let _inviteCodes = inviteCodes;
    if (typeof inviteCodes !== 'object') {
      _inviteCodes = [];
    }
    if (_inviteCodes.some(item => !item)) {
      message.error('邀请码不能为空');
      return;
    }
    if (_inviteCodes.length >= 10) {
      message.error('邀请码不能超过10个');
      return;
    }
    if (_inviteCodes.some(item => item.length > 8)) {
      message.error('邀请码不能超过8位');
      return;
    }
    if (_inviteCodes.some(item => item.length < 4)) {
      message.error('邀请码不能少于4位');
      return;
    }
    if (hasDuplicates(_inviteCodes)) {
      message.error('邀请码不能重复');
      return;
    } 
    const newInviteCodes = _inviteCodes.slice();
    newInviteCodes.push('');
    setInviteCodes(newInviteCodes);
    onChange?.(newInviteCodes);
  }

  const onDelete = (index) => {
    const newInviteCodes = inviteCodes.slice();
    newInviteCodes.splice(index, 1);
    setInviteCodes(newInviteCodes);
    onChange?.(newInviteCodes);
  }

  return <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
    {
      inviteCodes?.map?.((code, index) => {
        console.log('code: ', code);
        return <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <Input key={index} value={code} onBlur={(e) => {
            const _value = e.target.value;
            if (_value.length > 8) {
              message.error('邀请码不能超过8位');
              return;
            }
            console.log('inviteCodes: ', inviteCodes, _value);
            inviteCodes.filter(item => item === _value).length > 1 && message.error('邀请码不能重复');
          }} onChange={(e) => {
            const _value = e.target.value;

            if (_value.length > 8) {
              message.error('邀请码不能超过8位');
              return;
            }

            const newInviteCodes = inviteCodes.slice();
            newInviteCodes[index] = e.target.value;
            setInviteCodes(newInviteCodes);
            onChange?.(newInviteCodes);
          }} />
          <Button type="text" onClick={onDelete?.bind(null, index)}><DeleteOutlined /></Button>
        </div>
      })
    }
    <Button onClick={appendParam} style={{ width: '100%' }} type="dashed">添加邀请码</Button>
  </div>
}

const hasDuplicates = (arr) => {
  return new Set(arr).size !== arr.length;
}

export interface AppBasicInfoFormProps {
  workflow?: WorkflowItem;
  formRef?: React.RefObject<FormInstance>;
}

const AppBasicInfoForm = (props: AppBasicInfoFormProps) => {
  const { workflow, formRef } = props;
  const [form] = Form.useForm();
  const [isPrivate, setIsPrivate] = useState('N' as 'Y' | 'N');

  useEffect(() => {
    if (workflow) {
      console.log('workflow: ', workflow);
      const newFieldsValue = {
        name: workflow.name,
        code: workflow.code || uuid().slice(0, 15),
        description: workflow.description,
        isPrivate: workflow.isPrivate || 'N',
        cover: workflow.cover,
      };
      if (workflow.inviteCodes) {
        try {
          if (typeof workflow.inviteCodes === 'string') {
            newFieldsValue.inviteCodes = JSON.parse(workflow.inviteCodes);
          } else if (typeof workflow.inviteCodes === 'object') {
            newFieldsValue.inviteCodes = workflow.inviteCodes;
          } else {
            console.error(`inviteCodes type error: ${workflow.inviteCodes}`);
            newFieldsValue.inviteCodes = [];
          }
        } catch (e) {
          console.error(`parse inviteCodes error: ${e}`);
          newFieldsValue.inviteCodes = [];
        }
      }
      form.setFieldsValue(newFieldsValue);
      setIsPrivate(workflow.isPrivate === 'N' ? 'N' : 'Y');
    }
  }, [workflow]);

  return <div>
    <Form
      ref={formRef}
      labelCol={{ span: 2 }}
      form={form}
      onFieldsChange={(changedFields) => {
        if (changedFields[0]?.name[0] === 'isPrivate') {
          setIsPrivate(changedFields[0].value);
        }
      }}
    >
      <div className={style.modelSection}>
        <h2>基本信息</h2>
        <div>
          <Form.Item name="name" label="应用名称" rules={[{ required: true }]}>
                <Input placeholder="应用名称" />
              </Form.Item>
              <Form.Item name="code" label="应用别名" rules={[{ 
                required: true, 
                pattern: new RegExp(/^[a-zA-Z0-9_-]{6,15}$/),
                message: "应用别名必须是6-15位英文字符"
              }]}
              >
                <Input
                  disabled
                  placeholder="应用唯一编码，全英文"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="description" label="应用描述">
                <Input.TextArea placeholder="应用描述" />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="isPrivate" label="是否公开" rules={[{ required: false }]}>
                <WorkflowPrivateSwitch />
              </Form.Item>
            </div>
            { isPrivate === 'Y' ?  <div>
              <Form.Item name="inviteCodes" label="邀请码" rules={[{ required: false }]}>
                <InviteCodeInputs />
              </Form.Item>
            </div> : null}
            <div>
          <Form.Item name="cover" label="封面图" rules={[{ required: false }]}>
            <AvatarUploader enableCrop />
          </Form.Item>
        </div>
      </div>
    </Form>
  </div>
}

export default AppBasicInfoForm;
