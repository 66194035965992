import { CommonResponse } from '@/models/common/response';
import { ComfyNode, ParamNode } from '@/models/sd/comfy';
import request from '@/utils/request';

export type WorkflowDTO = {
  name?: string;
  description?: string;
  cover?: string;
  code?: string;
  engine?: string;

  contentTpl?: string;
  paramTpl?: string;
  outputTpl?: string;
};

export type RunWorkflowParam = {
  workflowCode: string;
  params: Record<string, unknown>;
};

export async function listWorkflows({isDefault}): Promise<CommonResponse<WorkflowDTO[]>> {
  const params = isDefault ? { isDefault } : {};
  const response = await request
    .get<string, CommonResponse<WorkflowDTO[]>>(
      `/open/api/v1/workflows`,
      {
        params
      }
    );
  return response;
}

export async function listMaletteWorkflows(params): Promise<CommonResponse<WorkflowDTO[]>> {
  const response = await request
    .get<string, CommonResponse<WorkflowDTO[]>>(
      `/api/v1/malette/my-workflows`,
      {
        params
      }
    );
  return response;
}

export async function getMaletteWorkflowDetail(publicId: string): Promise<CommonResponse<WorkflowDTO>> {
  const response = await request
    .get<string, CommonResponse<WorkflowDTO>>(
      `/api/v1/malette-workflow/${publicId}`
    );
  return response;
}

export async function getWorkflowAppDetail(code: string): Promise<CommonResponse<WorkflowDTO>> {
  const response = await request
    .get<string, CommonResponse<WorkflowDTO>>(
      `/open/api/v1/workflows/${code}`
    );
  return response;
}

export async function createOrUpdateWorkflow(workflow: WorkflowDTO): Promise<CommonResponse<WorkflowDTO>> {
  const response = await request
    .post<string, CommonResponse<WorkflowDTO>>(
      `/api/v1/malette/workflows/createOrUpdate`,
      {
        ...workflow
      }
    );
  return response;
} 

export async function deleteWorkflow(publicId: string): Promise<CommonResponse<WorkflowDTO>> {
  const response = await request
    .post<string, CommonResponse<WorkflowDTO>>(
      `/api/v1/malette/workflows/delete/${publicId}`,{}
    );
  return response;
}

export async function createWorkflow(workflow: WorkflowDTO): Promise<CommonResponse<WorkflowDTO>> {
  const response = await request
    .post<string, CommonResponse<WorkflowDTO>>(
      `/open/api/v1/workflow/create`,
      {
        workflow
      }
    );
  return response;
}

export async function runWorkflow(params: RunWorkflowParam, inviteCode): Promise<CommonResponse<Record<string, unknown>>> {
  const response = await request
    .post<string, CommonResponse<Record<string, unknown>>>(
      `/open/api/v1/workflow/run?inviteCode=${inviteCode}`,
      params
    );
  return response;
}

export async function listWorkflowCodes(): Promise<CommonResponse<WorkflowDTO[]>> {
  const response = await request
    .get<string, CommonResponse<WorkflowDTO[]>>(
      `/open/api/v1/workflow/codes`
    );
  return response;
}