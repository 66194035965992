import { Loading } from "@/components/Loading";
import { getWorkflowInviteCode, onDownload, sleep } from "@/utils";
import { DownloadOutlined, SwapOutlined, RotateLeftOutlined, RotateRightOutlined, ZoomOutOutlined, ZoomInOutlined, DownCircleOutlined, UpCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { Spin, Space, Button, message, Image } from "antd";

import styles from './index.module.scss';
import { useCallback, useEffect, useRef, useState } from "react";
import { SdTask, SdTaskResult } from "@/models/sd/SdFile";
import { listWorks, listWorksPaged } from "@/services/Works";
import { useGlobalStore } from "@/store";
import { FieldData } from "@/constants";
import { getSdTask, listTaskResultsByTaskIds } from "@/services/SdFile";
import { getProfileData } from "@/services/user";
import { Profile } from "@/models/common/user";
import HtmlOutputRender, { TextOutputRender } from "./HtmlOutputRender";
import html2canvas from "html2canvas";

const ResultPreview = (props) => {
  const { item, index, setPreviewIndex, previewIndex } = props;
  if (item.loading) {
    return <div key={index} className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
      <Spin />
    </div>
  }
  if (item.results?.indexOf('```') !== -1 || item.contentType === 'HTML') {
    try {
      return <div key={index} className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
        <span>{item.params?.params?.text23 || item.title || '文本输出'}</span>
      </div>
    } catch (e) {
      console.error('parse origin results failed: ', e);
    }
  } else if (item.contentType === 'TEXT') {
    return <div key={index} className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
      <span>{item.title || '文本输出'}</span>
    </div>
  }

  return (
    <div key={index} className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
      { 
        /[\u3400-\u9FBF]+/.test(item.results) 
          ? <span>{item.results}</span> 
          : item.results.endsWith('.webm') || item.results.endsWith('.mp4')
          ? <video style={{objectFit: 'contain', maxHeight: '100%'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${item.results}`} loop /> 
          : <img style={{objectFit: 'contain', maxHeight: '100%'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${item.results}${(item.type === 'sora_001' || item.results?.endsWith('gif')) ? '' : '!xs'} `} />  }
    </div>
  )
}

const convertHtmlToImage = (elementId) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }
  html2canvas(element, {
    backgroundColor: null,
    allowTaint: true,
    useCORS: true,
  }).then((canvas) => {
    const img = canvas.toDataURL('image/png');
    const a = document.createElement('a');
    a.href = img;
    a.download = 'malette.png';
    a.click();
  });
}

const ResultPreviewGroup = (props) => {
  const { previewIndex, setPreviewIndex, sdTaskResults, isMobilePortrait } = props;

  const result = sdTaskResults[previewIndex];
  const loading = result?.loading;
  const imagePath = result?.results;
  const audioUrl = result?.audioUrl;
  const videoUrl = result?.videoUrl;
  const styleSuffix = (result?.type === 'sora_001' || imagePath?.endsWith('gif')) ? '' : '!wm';
  const handleDownload = () => {
    if (result.results?.indexOf('```') !== -1 || result.contentType === 'HTML') {
      convertHtmlToImage('htmlRender');
    } else if (result.contentType === 'TEXT') {
      console.error('text output, no download');
    } else {
      onDownload(
        sdTaskResults[previewIndex]?.publicId, 
        `https://ablula.oss-accelerate.aliyuncs.com/${sdTaskResults[previewIndex]?.results}`,
        result?.results?.endsWith?.('.webm') || result?.results?.endsWith?.('.mp4') ? 'video' : 'image'
      );
    }
  }
  let content;
  if (videoUrl) {
    content = <video style={{objectFit: 'contain', maxHeight: '100%'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${videoUrl}`} controls loop />;
  } else if (audioUrl) {
    content = <audio style={{objectFit: 'contain'}} src = {`https://ablula.oss-accelerate.aliyuncs.com/${audioUrl}`} controls loop ></audio>;
  } else if (loading) {
    content = <Loading visibility="visible" />;
  } else if (imagePath) {
    if (result.results?.indexOf('```') !== -1 || result.contentType === 'HTML') {
      try {
        content = <HtmlOutputRender data={result.results} isMobilePortrait={isMobilePortrait} />
      } catch (e) {
        console.error('parse origin results failed: ', e);
      }
    } else if (result.contentType === 'TEXT') {
      content = <TextOutputRender data={result.results} isMobilePortrait={isMobilePortrait} />
    } else if (/[\u3400-\u9FBF]+/.test(imagePath)) {
      content = <span>{imagePath}</span>;
    } else if (imagePath.endsWith('.webm') || imagePath.endsWith('.mp4')) {
      content = <video style={{objectFit: 'contain'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}`} controls loop />;
    } else {
      content = <Image
        height="100%"
        style={{ maxHeight: '100%' }}
        className={styles.bigImageChild} 
        src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}${styleSuffix}`} />;
    }
  }

  return (
    <Image.PreviewGroup
      preview={{
        current: previewIndex,
        onChange: setPreviewIndex,
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <DownloadOutlined onClick={() => {
              onDownload(sdTaskResults[previewIndex]?.publicId, `https://ablula.oss-accelerate.aliyuncs.com/${sdTaskResults[previewIndex]?.results}`);
            }} />
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
      items={sdTaskResults?.map?.(item => 
        `https://ablula.oss-accelerate.aliyuncs.com/${item.results}${(item.type === 'sora_001' || item.results?.endsWith('gif')) ? '' : '!wm'}`
      )}
    > 
      <div className="relative w-full h-full flex items-center justify-center">
        {content}
        <div className="absolute h-8 right-0 top-2" style={{
          visibility: loading || (result.contentType === 'TEXT' && result.results?.indexOf('```') === -1) ? 'hidden' : 'visible',
        }}>
          <Button ghost type="text" onClick={handleDownload}>
            <DownloadOutlined style={{ fontSize: 20 }}/>
          </Button>
        </div>
      </div>
      
    </Image.PreviewGroup>
  )
}

export interface IOutputRenderProps {
  code?: string;
  onSelect?: (works?: SdTaskResult) => void;
  generating?: boolean;
  isMobilePortrait?: boolean;
  isNoHead?: boolean;
}

const NormalOutputRender = (props: IOutputRenderProps) => {
  const { code, onSelect, generating, isMobilePortrait, isNoHead } = props;
  const [dragging, setDragging] = useState(false);
  const [dragWarning, setDragWarning] = useState(false);
  const [topHeight, setTopHeight] = useState(innerHeight * .8);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [sdTaskResults, setSdTaskResults] = useState([]);
  const currentUser = useGlobalStore((state) => state.currentUser);

  const [unfinishedTasks, setUnfinishedTasks] = useState<SdTask[]>([]);
  const [profileData, setProfileData] = useState<Profile>({});
  const [previewIndex, setPreviewIndex] = useState(0);
  const loader = useRef(null);

  useEffect(() => {
    if (!currentUser?.publicId) {
      return;
    }
    const init = async () => {
      const profileResp = await getProfileData();
      if (profileResp?.data) {
        setProfileData(profileResp.data);
      }
    }
    init();
  }, [currentUser]);

  useEffect(() => {
    console.log('generating: ', generating);
    if (generating) {
      setPreviewIndex(0);
    }
    async function loadGenerating() {
      const unfinishedTasksResp = await listWorks({ type: code, unfinished: true});
      const unfinishedTasks = unfinishedTasksResp.data;
      setUnfinishedTasks(unfinishedTasks as SdTask[]);
    }
    loadGenerating();
  }, [generating]);

  const updateSdTaskResults = async (sdTaskResults, unfinishedTasks) => {
    const realSdTaskResults = sdTaskResults.filter(item => !item.loading);
    const newResults = [
      ...(unfinishedTasks.map(item => {
        return {
          loading: true,
        }
      })),
      ...realSdTaskResults,
    ];
    setSdTaskResults(newResults as any);
  }

  useEffect(() => {  
    updateSdTaskResults(sdTaskResults, unfinishedTasks);
    const loopFetchResult = async () => {
      let hasResult = false;
      while (!hasResult) {
        unfinishedTasks.forEach(async item => {
          const taskResp = await getSdTask(item.publicId as string);
          if (taskResp?.data?.stage === 'FINISHED') {
            hasResult = true;
          }
        });
        if (!hasResult) {
          await sleep(2000);
        }
      }
      const taskIds = unfinishedTasks.map(item => item.publicId);
      const taskResultsResp = await listTaskResultsByTaskIds(taskIds);

      if (taskResultsResp?.data?.length) {
        const leftUnfinishedTasks = unfinishedTasks.filter(item => !taskResultsResp.data.map((item: SdTaskResult) => item.taskId).includes(item.publicId));
        setUnfinishedTasks(leftUnfinishedTasks);
        const newResults = [
          ...(leftUnfinishedTasks.map(item => {
            return {
              loading: true,
            }
          })),
          ...taskResultsResp.data,
          ...sdTaskResults,
        ];
        setSdTaskResults(newResults as any);
      }
      setTimeout(() => {
        setPreviewIndex(0);
      });
    }
    loopFetchResult();
  }, [unfinishedTasks]);

  useEffect(() => {
    const currentTaskResult = sdTaskResults?.[previewIndex];
    onSelect?.(currentTaskResult);
  }, [previewIndex, sdTaskResults]);
  
  const loadMoreData = useCallback(async () => {
    if (!currentUser?.publicId) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await listWorksPaged(code, pageNo, pageSize);
      setIsLoading(false);
      const data = await response.data
      const { list, hasMore, total } = data;
      setSdTaskResults(prevData => [...prevData, ...list]);
      setHasMoreData(hasMore);
      if (hasMore) {
        setPageNo(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }

  }, [code, pageNo, pageSize]);

  const handleObserver = useCallback((entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasMoreData && !isLoading) {
      loadMoreData();
    }
  }, [isLoading, hasMoreData, loadMoreData]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  // 开始拖拽事件
  const startDragging = (e) => {
    e.preventDefault();
    setDragging(true);
    window.addEventListener('mousemove', onDrag);
    window.addEventListener('mouseup', stopDragging);
  };

  // 拖拽事件
  const onDrag = (e) => {
    if (e.clientY < innerHeight * .2 || e.clientY > innerHeight * .8) {
      setDragWarning(true);
      return;
    }
    setDragWarning(false);
    setTopHeight(e.clientY);
  };

  // 停止拖拽事件
  const stopDragging = () => {
    setDragging(false);
    window.removeEventListener('mousemove', onDrag);
    window.removeEventListener('mouseup', stopDragging);
  };


  const handleToggleHistory = () => {
    if (topHeight < (innerHeight * .8 - 10)) {
      setTopHeight(innerHeight * .8);
    } else {
      setTopHeight(innerHeight * .2);
    }
  }

  return <div className={isMobilePortrait ? '' : 'h-full py-4'}>
    <div
      className={`${styles.imageContainer} flex items-center justify-center p-2 rounded-lg`}
      style={isMobilePortrait ? {
        height: 600,
        maxHeight: 600,
      } : { 
        height: `${topHeight - (isNoHead ? 40 : 88)}px`, 
        transition: dragging ? 'none' : 'height 1s'
      }}>
      {sdTaskResults?.length ? <ResultPreviewGroup
        isMobilePortrait={isMobilePortrait}
        previewIndex={previewIndex}
        setPreviewIndex={setPreviewIndex}
        sdTaskResults={sdTaskResults}
      /> : null}
    </div>
    <div
      className={styles.dragHandle}
      onMouseDown={startDragging}
      style={{ display: isMobilePortrait ? 'none' : 'block', border: dragging && dragWarning ? '1px dashed red': '1px dashed #ccc' }}
    >
      <div className={styles.quickHandle}>
        <Button type="text" onClick={handleToggleHistory}>
          {
            topHeight < (.8 * innerHeight - 10)
              ? <DownCircleOutlined style={{ fontSize: 20, color: 'gray', background: 'white', borderRadius: '50%' }} />
              : <UpCircleOutlined style={{ fontSize: 20, color: 'gray', background: 'white', borderRadius: '50%' }} />
          }
        </Button>
      </div>
    </div>
    <div 
      className={styles.imageListContainer} 
      style={isMobilePortrait ? {
        height: '100%',
      } : { 
        height: `calc(100vh - ${isNoHead ? '-5px' : '10px'} - ${topHeight}px)`, 
      }}>
      { sdTaskResults?.length ? sdTaskResults.map((item, index) => 
        <ResultPreview 
          key={index} 
          item={item} 
          index={index} 
          setPreviewIndex={setPreviewIndex} 
          previewIndex={previewIndex} />) 
        : null 
      }
      <div className="flex items-center justify-center">
        <div id="loader" ref={loader} className="w-full h-4">
          {isLoading && <Spin><div></div></Spin>}
        </div>
      </div>
    </div>
  </div>
}

export { NormalOutputRender };